<template>
  <div class="wrapper-strategy">
    <div class="strategy-inner">
      <div class="strategy-content">
        <div class="strategy-content-inner">
          <dv-border-box-1 style="width: 40%; height: 100%">
            <borderBox
              style="width: 100%; height: 100%"
              class="strategy-info border-box-content"
              :src="src"
            >
              <span slot="title" class="title border-title">维保列表</span>
              <div slot="box-container" class="box-container">
                <el-table
                  :data="tableData"
                  border
                  style="width: 100%"
                  @row-click="handleSelectedRow"
                >
                  <el-table-column prop="mNumber" label="维保编号" width="180">
                  </el-table-column>
                  <el-table-column prop="mType" label="维保类型" width="100">
                  </el-table-column>
                  <el-table-column prop="mWorker" label="维保人员">
                  </el-table-column>
                  <el-table-column prop="mTime" label="维保时间">
                  </el-table-column>
                </el-table>
              </div>
            </borderBox>
          </dv-border-box-1>
          <dv-border-box-1 style="width: 60%; height: 100%">
            <borderBox
              style="width: 100%; height: 100%"
              class="strategy-info border-box-content"
              :src="src"
            >
              <span slot="title" class="title border-title">维保列表</span>
              <div slot="box-container" class="box-container">
                <el-table
                  :data="detailData"
                  border
                  style="width: 100%"
                  height="650"
                >
                  <el-table-column prop="mNumber" label="序号" width="180">
                  </el-table-column>
                  <el-table-column
                    prop="mContext"
                    label="维保项目(内容)"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column prop="mRequire" label="维保基本要求">
                  </el-table-column>
                  <el-table-column prop="mResult" label="维保结果">
                  </el-table-column>
                </el-table>
              </div>
            </borderBox>
          </dv-border-box-1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRecordHalfMonthData,
  getRecordSeasonData,
  getRecordHalfYearData,
  getRecordYearData,
  getRecordHalfMonthDetails,
  getRecordSeasonDetails,
  getRecordHalfYearDetails,
  getRecordYearDetails
} from '@/api/record.js'
export default {
  data() {
    return {
      src: require('../../../assets/img/info.png'),
      tableData: [],
      detailData: []
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.getRecordData()
        this.detailData = []
      },
      immediate: true
    }
  },
  created() {
    this.getRecordData()
  },
  methods: {
    getRecordData() {
      const equipment = this.$route.query.equipment
      const recordType = this.$route.query.recordType
      if (recordType === '1') {
        getRecordHalfMonthData(equipment).then((res) => {
          this.tableData = res
        })
      } else if (recordType === '2') {
        getRecordSeasonData(equipment).then((res) => {
          this.tableData = res
        })
      } else if (recordType === '3') {
        getRecordHalfYearData(equipment).then((res) => {
          this.tableData = res
        })
      } else {
        getRecordYearData(equipment).then((res) => {
          this.tableData = res
        })
      }
    },
    //获取选中的详细维保数据
    handleSelectedRow(row) {
      var rid = row.mNumber
      const recordType = this.$route.query.recordType
      const equipment = this.$route.query.equipment

      if (recordType == 1) {
        getRecordHalfMonthDetails(equipment, rid).then((res) => {
          this.detailData = res
        })
      } else if (recordType == 2) {
        getRecordSeasonDetails(equipment, rid).then((res) => {
          this.detailData = res
        })
      } else if (recordType == 3) {
        getRecordHalfYearDetails(equipment, rid).then((res) => {
          this.detailData = res
        })
      } else {
        getRecordYearDetails(equipment, rid).then((res) => {
          this.detailData = res
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-strategy {
  width: 100%;
  height: 90%;
  padding: 0 46px;
  .strategy-inner {
    width: 100%;
    height: 88%;

    ul {
      display: flex;
      li {
        border-bottom: 1px solid #1ed0f8;
        margin-left: 2.66%;
        font-size: 16px;
        color: #1ed0f8;
        padding: 5px 0;
      }
    }
    .strategy-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .strategy-content-inner {
        width: 100%;
        display: flex;
      }
    }
  }
}

.strategy-info {
  box-title {
    padding-top: 14px;
    padding-left: 13px;
    padding-right: 13px;
  }
  ::v-deep.el-table__empty-text {
    color: #fff;
  }
  .box-container {
    padding: 18px;
  }

  ::v-deep.el-table thead {
    color: #63e2ff;
  }
  ::v-deep.el-table--border,
  .el-table--group {
    border: 1px solid #0d9bd8;
  }
  .el-table--border,
  .el-table--group {
    background: rgba(13, 155, 216, 0);
  }
  ::v-deep.el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #0d9bd8;
  }

  ::v-deep.el-table--border .el-table__cell,
  .el-table__body-wrapper .el-table--border.is-scrolling-left .el-table__fixed {
    border-right: 1px solid #0d9bd8;
  }
  ::v-deep.el-table tr {
    background: rgba(13, 155, 216, 0);
  }

  ::v-deep.el-table {
    color: #68d8f2;
    font-size: 16px;
  }

  ::v-deep.el-table__header-wrapper {
    background: rgba(13, 155, 216, 0);
  }

  ::v-deep.el-table,
  .el-table__expanded-cell {
    background: rgba(13, 155, 216, 0);
  }
  :v-deep.el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #0d9bd8;
  }

  ::v-deep.el-table th.el-table__cell {
    background: rgba(13, 155, 216, 0);
  }

  ::v-deep.el-table--enable-row-hover .el-table__body tr:hover > td {
    background: linear-gradient(
      -45deg,
      rgba(0, 108, 242, 1) 10.45%,
      rgba(0, 109, 242, 1) 46.97%,
      rgba(0, 111, 243, 1) 90.84%
    );
    color: #fff;
  }

  ::v-deep.el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background: none;
  }

  ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #0d9bd8 !important;
  }
}
</style>
