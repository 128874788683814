import request from '@/utils/request.js'

//获取设备的维保记录集合

//半月保
export function getRecordHalfMonthData(did) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/half-month-records',
    method: 'get'
  })
}

export function getRecordHalfMonthDetails(did, rid) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/half-month-records/' + rid,
    method: 'get'
  })
}

//季度保
export function getRecordSeasonData(did) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/season-records',
    method: 'get'
  })
}

export function getRecordSeasonDetails(did, rid) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/season-records/' + rid,
    method: 'get'
  })
}
//半年保

export function getRecordHalfYearData(did) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/half-year-records',
    method: 'get'
  })
}

export function getRecordHalfYearDetails(did, rid) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/half-year-records/' + rid,
    method: 'get'
  })
}

//年度保

export function getRecordYearData(did) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/year-records',
    method: 'get'
  })
}

export function getRecordYearDetails(did, rid) {
  return request({
    url: '/api/v1/devices' + '/' + did + '/year-records/' + rid,
    method: 'get'
  })
}
